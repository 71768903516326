import React from 'react'
import './textCard.scss'
import Button from '../../../button'

const TextCard = props => {

    const getIcon = () => {
        if ( props.icon.length ) {
            const icon = props.icon[0];

            return (<div className="card-icon-wrapper">
                <img className="card-icon" src={ icon.url } />
            </div>)
        }
    }
    
    return (
        <div className={ `text-card ${props.white ? 'white-bg' : ''}` }>
            <div className={`card-content`}>
                { getIcon() }
                <p className="card-headline my-1">{ props.headline }</p>
                <div className="description rich-text" dangerouslySetInnerHTML={ { __html: props.description } }></div>
                { props.button.url && <Button type={ props.button.type } buttonText={ props.button.text } url={ props.button.url } smallSize={ true } noBackground={ false } />}
            </div>
        </div>
    )
}

export default TextCard;
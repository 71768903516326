import React from 'react'
import TextCard from './textCard'
import ImageCard from './imageCard'
import styles from './cardLayout.module.scss'
import classnames from 'classnames'
import VimeoCard from './vimeoCard'

const CardLayout = props => {

    const cardTypes = {
        textCards: TextCard,
        imageCards: ImageCard,
        vimeoCards: VimeoCard,
    }

    const totalColumns = 12;
    const columnClasses = classnames(styles.cardWrapper, `col-12 col-sm-6 col-md-${totalColumns / props.columns}`);
    
    //just in case no cards are set 
    const cards = props.children.length ? props.children[0].cards : null;
    const cardType = cards ? props.children[0].component : null;
    const CardComponent = cardTypes[cardType];

    return (
        <div className="page-component cards py-2">
            <div className="inner-container">
                <div className="row">
                    { cards && cards.map( (card, index) => (
                        <div className={ columnClasses } key={ index }>
                            <CardComponent { ...card } index  white={ props.whiteBackground } />
                        </div>
                    )) }
                </div>
            </div>
        </div>
    )
}

export default CardLayout;
import React from 'react'

import AuthenticatedRouter from 'routers/authenticated-router'
import UnauthentictedRouter from 'routers/unauthenticated-router'

import { useAuthentication } from 'contexts/authentication'

const Routers = () => {
  const { token, user } = useAuthentication()

  if (token && user) {
    return <AuthenticatedRouter />
  }

  return <UnauthentictedRouter />
}

export default Routers
import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './iframe-embed.module.scss';


const IframeEmbed = props => {

    const iframe = useRef();

    const [frameStyle, setFrameStyle] = useState({
        paddingTop: 0,
    });

    const rootClasses = classnames(styles.root, 'page-component', 'iframe-embed')

    const frameWrapperClasses = () => {
        if ( props.maintainAspectRatio ) {
            return classnames( styles.frame, styles.maintainAspectRatio );
        }
        return styles.frame;
    }

    useEffect(()=>{

        const frameWrapper = iframe.current;
        const frame = frameWrapper.firstChild;
        const ratio = parseInt(frame.getAttribute('height')) / parseInt(frame.getAttribute('width'));

        if ( props.maintainAspectRatio ) {
            setFrameStyle({
                paddingTop: `${ratio * 100}%`,
            });
        }

    },[]);
    
    return (<div className={ styles.root }>
        <div className="inner-container">
            <div className="row">
                <div className="col-12 mt-4">
                { props.blockTitle && <header className="mb-4" dangerouslySetInnerHTML={ { __html: props.blockTitle } }></header> }
                    <div ref={ iframe } className={ frameWrapperClasses() } style={ frameStyle } dangerouslySetInnerHTML={ { __html: props.iframeEmbed } }></div>
                </div>
            </div>
        </div>
    </div>)
}

export default IframeEmbed;
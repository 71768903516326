import React, { useState, useRef } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

import styles from './sideTabs.module.scss'

const Tab = ({ title, active, onClick }) => {
  const tabClasses = classnames(
    styles.tab,
    { [styles.activeTab]: active }
  )

  return (
    <div className={ tabClasses } onClick={ onClick }>
      <h6 className="title-3">{ title }</h6>
    </div>
  )
}

const TabImage = ({ image, caption }) => {  
  const { width, height, url } = image

  return (
    <div
      className={ styles.image }
    >
      <div
        className={ styles.imageSizer }
        style={{
          paddingBottom: `${(height / width) * 100}%`,
          backgroundImage: `url('${url}')`
        }}
      />

      { caption &&
        <p className="caption">{ caption }</p>
      }
    </div>
  )
}

const SideTabs = ({ tabs }) => {
  const [ activeTab, setActiveTab ] = useState(0)
  const imagesContainer = useRef()

  return (
    <div className="page-component side-tabs inner-container">
      <div className="row">
        <div className="col-12 col-sm-4 col-md-3 col-lg-2">
          { tabs.map(({ title }, index) => (
            <Tab 
              key={ index }
              title={ title }
              active={ activeTab === index }
              onClick={ () => setActiveTab(index) }
            />
          ) )}
        </div>
        <div className="col-12 col-sm-8 col-md-9 col-lg-10">
          <div ref={ imagesContainer } className={ styles.imagesContainer }>
            <TransitionGroup component={ null }>
              <CSSTransition
                key={ activeTab }
                timeout={ 500 }
                classNames={{ ...styles }}
                onExit={ node => imagesContainer.current.style.height = `${node.scrollHeight}px` }
                onEntering={ node => imagesContainer.current.style.height = `${node.scrollHeight}px` }
                onEntered={ () => imagesContainer.current.style.height = null }
              >
                <TabImage
                  image={ tabs[activeTab].image[0] }
                  caption={ tabs[activeTab].caption }
                />
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideTabs
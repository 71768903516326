import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './banner.module.scss';

import { useGlobalContent } from 'contexts/global-content'

const Banner = ({ headline, smallHeadline, image, width, textAlignment, alignment, first }) => {

  const componentRef = useRef()
  const { setTransparentHeader } = useGlobalContent()

  const BannerStyle = {
    backgroundImage: `url(${image[0].url})`,
    backgroundPosition: `${image[0].focalPoint[0]*100}% ${image[0].focalPoint[1]*100}%`
  }

  const rootClasses = classnames(
    styles.root, 'page-component', 'banner',
    { [styles.first]: first }
  )

  const widthClasses = classnames('col-12',`col-sm-${ width ? width : '12'}`, 'py-4');
  const blockAlignmentClasses = classnames('row', styles[alignment] || styles['leftAligned']);
  const textAlignmentClasses = classnames(styles[textAlignment]);

  useEffect(() => {
    let observer

    const handleIntersection = entries => {
      setTransparentHeader(entries.some(e => e.isIntersecting))
    }

    if (first) {
      observer = new IntersectionObserver(handleIntersection)
      observer.observe(componentRef.current)
    }

    return () => {
      if (observer) {
        observer.disconnect()
        
        setTransparentHeader(false)
      }
    }
  }, [first])

  return (
    <div className={rootClasses}>
      <div ref={ componentRef } className={styles.background} style={BannerStyle}></div>
      <div className={styles.content}>
        <div className="inner-container">
          <div className={ blockAlignmentClasses }>
            <div className={ `${widthClasses} ${textAlignmentClasses}` }>
              {smallHeadline && <p className={ styles.subheadline }>{ smallHeadline }</p>}
              <h1>{headline}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner;
import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import styles from './footer.module.scss'
import { useGlobalContent } from 'contexts/global-content'
import { useCraft } from 'contexts/craft'


const Footer = () => {

    const { footer } = useGlobalContent();
    const { pageData } = useCraft()

    const year = () => {
        const now = new Date();
        return now.getFullYear();
    }

    if (!pageData) return null;

    if ( !footer ) return null;
    
    const showContactButton = pageData.entry.showFooterContactButton;
    const footerContactEmail = pageData.entry.footerContactButtonEmailOverride && pageData.entry.footerContactButtonEmailOverride.length > 0 ? pageData.entry.footerContactButtonEmailOverride : footer.footerHelpEmail;

    return (
        <footer className={ classnames(styles.root, pageData.dark ? styles.dark : '') }>
            { footer && <div className="inner-container">
                <div className="row my-3">
                    <div className="col-12">
                        <hr/>
                    </div>
                </div>
                
                    <div className="row">
                    <div className="col-12 col-md-5">
                        <h3>{ footer.footerHeadline }</h3>
                        { showContactButton && <p><a href={ `mailto:${footerContactEmail}`} className="btn small">Get in Touch</a></p>}
                        </div>

                    </div>
               
                <div className="row my-3 justify-content-between">
                    <div className={ classnames('col-12', 'col-md-4', '', styles.legal) }>&copy; { year() } Nike Inc. All Rights Reserved.</div>
                    <div className={ classnames('col-12 col-md-7', styles.navCol) }>
                        <nav className={ styles.nav }>
                            <div className="mb-2">
                                { footer.footerNavigation && footer.footerNavigation.map( (link, index) => {
                                    if ( link.type === 'externalLink' ) {
                                        return (<a className={ styles.navLink } href={ link.linkUrl } target="_blank" key={ index }>{ link.linkLabel }</a>)
                                    }
                                    return (<a className={ styles.navLink } href={ `/${link.pageLink[0].uri}` } key={ index }>{ link.linkLabel }</a>)
                                })}
                            </div>
                        </nav>
                    </div>
                </div>
            </div> }
        </footer>)
}

export default Footer

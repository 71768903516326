import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import LoginPage from 'pages/login-page'
import PasswordResetPage from 'pages/password-reset-page'
import LostPasswordPage from 'pages/lost-password-page'

const UnauthenticatedRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact>
          <LoginPage />
        </Route>

        <Route path="/lost-password" exact>
          <LostPasswordPage />
        </Route>

        <Route path="/password-reset" exact>
          <PasswordResetPage />
        </Route>

        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  )
}

export default UnauthenticatedRouter
require('intersection-observer')

import React from 'react'
import { render } from 'react-dom'

import AuthenticationProvider from 'contexts/authentication'

import Routers from 'routers'

import 'styles/index.scss'

const Nike365Studio = () => (
  <AuthenticationProvider>
    <Routers />
  </AuthenticationProvider>
)

render(
  <Nike365Studio />,
  document.getElementById('nike-365-studio')
)
import React from 'react'
import styles from './textBlock.module.scss'
import Button from '../../button'
import classnames from 'classnames'

const TextBlock = props => {

    const alignmentClasses = classnames(styles[props.alignment], styles[props.textAlignment || 'textLeft'],  'row');
    const columnClassess = classnames( 'col-12',`col-sm-${ props.width ? props.width : '8'}`, styles.textBlock);


    return (<div className={ `page-component text-block inner-container py-2 ${props.first ? 'mt-5' : ''}` }>
                <div className={ alignmentClasses }>
                    <div className={ columnClassess }>
                        <div dangerouslySetInnerHTML={ { __html: props.richText } }></div>
                        { props.button && props.button.length > 0 && <Button {...props.button[0]} /> }
                    </div>
                </div>
            </div>)
}

export default TextBlock;
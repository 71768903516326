import React, {useRef, useEffect} from 'react'
import { Link } from 'react-router-dom'

import styles from './tertiary-links.module.scss'

const TertiaryLink = ({ label, page }) => {
  if (!page || page.length < 1) {
    return (
      <a className={ styles.tertiaryLink }>{ label }</a>
    )
  }

  return (
    <Link to={ `/${page[0].uri}` } className={ styles.tertiaryLink }>
      { label }
    </Link>
  )
}

const TertiaryLinks = ({ tertiary: teriaries, expanded = false }) => {
  const node = useRef()

  useEffect(() => {
    if (!node.current) return

    if (expanded) {
      node.current.style.height = `${node.current.scrollHeight}px`
      node.current.style.opacity = 1
    } else {
      node.current.style.height = null
      node.current.style.opacity = 0
    }
  }, [ expanded ])

  if (!teriaries || teriaries.length < 1) return null

  return (
    <div ref={ node } className={ styles.tertiaryLinks }>
      { teriaries.map((tertiary, index) => (
        <TertiaryLink key={ index } { ...tertiary } />
      ))}
    </div>
  )
}

export default TertiaryLinks
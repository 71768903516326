import React, { createContext, useContext, useState, useEffect } from 'react'

import api from 'utilities/api-client'

const AuthenticationContext = createContext()

const AuthenticationProvider = ({ children }) => {
  const [ token, setToken ] = useState(window.localStorage.getItem('token'))
  const [ user, setUser ] = useState(JSON.parse(window.localStorage.getItem('user')))

  const logout = async (callback) => {
    try {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      setUser(null)
      setToken(null)

      if ( callback ) {
        callback();
      }
    } catch (error) {
      // rethrow error for original caller
      throw error
    }
  }

  const resetPassword = async( loginName ) => {
    try {
      const result = await api.resetPassword( loginName );

      return result;

    } catch ( error ) {
      console.log( error );
    }
  }

  const login = async (username, password) => {
    try {
      const { success, message, statusCode, data } = await api.authenticate(username, password)

      if (!success) {
        const error = new Error(message)
        error.statusCode = statusCode
        
        throw error
      }

      if ( data.user ) {
        window.localStorage.setItem('user', JSON.stringify(data.user))
        setUser( data.user );
      }

      // set token in localstorage
      window.localStorage.setItem('token', data.token)

      setToken(data.token)

    } catch (error) {
      await logout()

      // rethrow error for original caller
      throw error
    }
  }

  const value = {
    token,
    resetPassword,
    user,
    login,
    logout
  }

  return (
    <AuthenticationContext.Provider value={ value }>
      { children }
    </AuthenticationContext.Provider>
  )
}

const useAuthentication = () => useContext(AuthenticationContext)

export default AuthenticationProvider
export { useAuthentication }
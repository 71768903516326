import React, { createContext, useState, useEffect, useRef, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import API from '../utilities/api-client'
import NProgress from 'nprogress'

import { useAuthentication } from 'contexts/authentication'

const CraftContext = createContext()

const minLoadingDelay = 1200;


const CraftProvider = ({ children }) => {
  const [ pageData, setPageData ] = useState(null)
  const [ pageClasses, setPageClasses ] = useState(['loaded']);
  const { token, logout } = useAuthentication()
  const location = useLocation()
  const history = useHistory()

  let transitionTimer = null;

  const completeLoading = ( pause ) => {

    window.clearTimeout(transitionTimer);

    if ( pause ) {
      window.setTimeout(()=>{
        //reset scroll position on page change
        window.scrollTo(0, 0);
        setPageClasses([]);
      }, pause );
    } else {
      //reset scroll position on page change
      window.scrollTo(0, 0);
      setPageClasses([]);
    }
    
  }

  const onPathChange = async pathname => {

    const start = new Date().getTime();

    setPageClasses(['pageLoading']);

    // NProgress.start()

    const { statusCode, data } = await API.content(pathname, token);

    if (statusCode === 401) {
      // NProgress.done()
      setPageClasses([]);
      logout()
      return
    }

    if (statusCode === 404) {
      history.replace('/not-found')
      setPageClasses([]);
      setPageData(null)
    } else {
      const delta = new Date().getTime() - start;

      if ( delta  < minLoadingDelay ) {
        //haven't met the minimum time requirement
        window.clearTimeout(transitionTimer);
        // delay removing the loading class according to how much time has elapsed since we fetched the page data.
        window.setTimeout(()=>{
          window.scrollTo(0, 0);
          setPageData({ dark: data.entry.uri === 'home', ...data })
          setPageClasses([]);
        }, (minLoadingDelay - delta) );

      } else {
        //met the minimum time requirement so go ahead and remove the loading class.
        window.scrollTo(0, 0);
        setPageData({ dark: data.entry.uri === 'home', ...data })
        setPageClasses([]);
      }
      
      
    }
  

    // NProgress.done()
  }

  const lastPathname = useRef()
  useEffect(() => {
    if (location.pathname !== lastPathname.current) {
      onPathChange(location.pathname)
    }
    // listen to hash changes in the page component and scroll to them

    lastPathname.current = location.pathname
  }, [location])

  const value = {
    pageData,
    pageClasses
  }

  return (
    <CraftContext.Provider value={ value }>
      { children }
    </CraftContext.Provider>
  )
}

const useCraft = () => useContext(CraftContext)

export default CraftProvider
export { useCraft }
import React from 'react'
import { useCraft } from 'contexts/craft'


const LineSpacer = props => {
    
    const { pageData } = useCraft()    

    return (<div className="inner-container">
                <div className="row">
                    <div className="col-12">
                        <div className={ props.spacing }>
{ props.line && <hr className={ pageData.dark ? 'on-black' : '' } /> }
                        </div>
                    </div>
                </div>
            </div>)
}

export default LineSpacer;
import React, { useState } from 'react'
import classnames from 'classnames'

import SecondaryLinks from './secondary-links'

import { useGlobalContent } from 'contexts/global-content'

import styles from './primary-links.module.scss'

const PrimaryLink = ({ active, label, secondary }) => {
  const [ hovered, setHovered ] = useState(false)

  const disabled = !secondary || secondary.length < 1
  
  const rootClasses = classnames(
    styles.primaryLink,
    { [styles.disabled]:  disabled, [styles.active]: active }
  )

  return (
    <div
      className={ rootClasses }
      onMouseEnter={ () => setHovered(true) }
      onMouseLeave={ () => setHovered(false) }
    >
      <a className="small">{ label }</a>
      <div className={ styles.underline } />

      { !disabled &&
        <SecondaryLinks primaryActive={ active } links={ secondary } open={ hovered } />
      }
    </div>
  )
}

const PrimaryLinks = () => {
  const { navigation, activeNav } = useGlobalContent();

  return (
    <nav className={ styles.mainNav }>
      { navigation && navigation.primaries.map((primary, index) => (
        <PrimaryLink key={ index } active={ activeNav[0] === index } { ...primary } />
      )) }
    </nav>
  )
}

export default PrimaryLinks
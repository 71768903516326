import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

import TertiaryLinks from './tertiary-links'

import { useGlobalContent } from 'contexts/global-content'

import styles from './secondary-links.module.scss'

const SecondaryLink = ({ label, tertiary, expanded, onClick }) => {
  const disabled = !tertiary || tertiary.length < 1

  const rootClasses = classnames(
    styles.secondaryLink,
    { [styles.disabled]:  disabled, [styles.expanded]: expanded }
  )

  return (
    <a className={ rootClasses } onClick={ disabled ? null : onClick }>
      { label }

      { !disabled &&
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20">
          <path d="M.311 18.082l1.415 1.414 9.535-9.535L1.604.304.19 1.718 8.433 9.96.31 18.082z" />
        </svg>
      }
    </a>
  )
}

const SecondaryLinks = ({ open, activePrimary }) => {
  const [ expandedSecondary, setExpandedSecondary ] = useState(-1)
  const { navigation } = useGlobalContent()

  const { label, secondary: secondaries } = navigation.primaries[activePrimary]

  return (
    <CSSTransition
      in={ open }
      timeout={ 250 }
      classNames={{ ...styles }}
      onExited={ () => setExpandedSecondary(-1) }
      mountOnEnter
      unmountOnExit
    >
      <div className={ styles.page }>
        <div className={ styles.secondaryLinks }>
          <h6>{ label }</h6>

          { secondaries.map((secondary, index) => (
            <div key={ index }>
              <SecondaryLink
                onClick={ () => setExpandedSecondary(index === expandedSecondary ? -1 : index) }
                expanded={ index === expandedSecondary }
                { ...secondary }
              />

              <TertiaryLinks tertiary={ secondary.tertiary } expanded={ index === expandedSecondary } />
            </div>
          ))}
        </div>
      </div>
    </CSSTransition>
  )
}

export default SecondaryLinks
import React from 'react'
import classnames from 'classnames'

import { useGlobalContent } from 'contexts/global-content'
import { useAuthentication } from 'contexts/authentication'

import styles from './primary-links.module.scss'

const PrimaryLink = ({ label, secondary, onClick }) => {
  const disabled = !secondary || secondary.length < 1

  const rootClasses = classnames(
    styles.primaryLink,
    { [styles.disabled]:  disabled }
  )

  return (
    <a className={ rootClasses } onClick={ disabled ? null : onClick }>
      { label }
      
      { !disabled &&
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20">
          <path d="M.311 18.082l1.415 1.414 9.535-9.535L1.604.304.19 1.718 8.433 9.96.31 18.082z" />
        </svg>
      }
    </a>
  )
}

const PrimaryLinks = ({ onLinkClick }) => {
  const { navigation } = useGlobalContent()
  const { logout } = useAuthentication()

  return (
    <div className={ styles.page }>
      <div className={ styles.primaryLinks }>
        { navigation && navigation.primaries.map((primary, index) => (
          <PrimaryLink key={ index } onClick={ () => onLinkClick(index) } { ...primary } />
        ))}

        <a className={ styles.signOut } onClick={ logout }>Sign Out</a>
      </div>
    </div>
  )
}

export default PrimaryLinks
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classnames from 'classnames'

import MobileMenu from 'components/mobile-menu'
import PrimaryLinks from './primary-links'
import UserDropdown from './userDropdown'

import { useGlobalContent } from 'contexts/global-content'
import { useAuthentication } from 'contexts/authentication'

import styles from './main-navigation.module.scss'

const UserInitial = user => {
  if ( user ) {
    return <span>{ user.name !== null ? user.name.charAt(0) : 'U' }</span> 
  }
  return <span>{ 'U' }</span>;
}

const MainNavigation = () => {
  const [ mobileOpen, setMobileOpen ] = useState(false)
  const [ userDropdown, setUserDropdown ] = useState(false);
  const { loading, navigation } = useGlobalContent()
  const { logout, user } = useAuthentication()
  const location = useLocation()

  useEffect(() => {
    setMobileOpen(false)
  }, [ location ])

  const headerClasses = classnames(
    styles.header,
    'fluid-container',
    { [styles.hidden]: loading }
  )

  return (
    <div className={styles.container}>
      <header className={ headerClasses } id="main-header">
        <Link
          to="/"
          className={styles.brand}
        >
          { navigation && <h5>{ navigation.homeLinkLabel }</h5> }
        </Link>

        <PrimaryLinks />

        <div className={ styles.userMenuWrapper }>
          <a className={ styles.avatar } onClick={ () => { setUserDropdown(!userDropdown) } }>
            <h5>{ UserInitial( user ) }</h5>
          </a>
          <UserDropdown user={ user } open={ userDropdown } logout={ logout } />
        </div>
        
        <a className={ styles.toggle } onClick={ () => setMobileOpen(true) }>
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 15">
            <path fillRule="evenodd" clipRule="evenodd" d="M.774 1.52a1 1 0 011-1h18.32a1 1 0 110 2H1.774a1 1 0 01-1-1zm0 6a1 1 0 011-1h18.32a1 1 0 110 2H1.774a1 1 0 01-1-1zm0 6a1 1 0 011-1h18.32a1 1 0 110 2H1.774a1 1 0 01-1-1z" />
          </svg>
        </a>
      </header>

      <MobileMenu open={ mobileOpen } onCloseClick={ () => setMobileOpen(false) } />
    </div>
  )
}

export default MainNavigation
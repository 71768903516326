import React, {useState} from 'react'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

import PrimaryLinks from './primary-links'
import SecondaryLinks from './secondary-links'

import styles from './mobile-menu.module.scss'

const MobileMenu = ({ open, onCloseClick }) => {
  const [ activePrimary, setActivePrimary ] = useState(0)
  const [ secondaryOpen, setSecondaryOpen ] = useState(false)

  const headerClasses = classnames(
    styles.header,
    { [styles.open]: secondaryOpen }
  )

  return (
    <CSSTransition
      in={ open }
      timeout={ 250 }
      classNames={{ ...styles }}
      onExited={ () => {
        setSecondaryOpen(false)
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={ styles.mobileMenu }>
        <header className={ headerClasses }>
          <div className={ styles.button }>
            <a className={ styles.search }>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
                <path d="M20.71 19.29L17 15.61A9 9 0 1015.61 17l3.68 3.68a1 1 0 001.42 0 1 1 0 000-1.39zM10 17a7 7 0 110-14 7 7 0 010 14z" />
              </svg>
            </a>
            <a className={ styles.back } onClick={ () => setSecondaryOpen(false) }>
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20">
                <path d="M11.293 18.082L9.88 19.496.343 9.961 10 .304l1.414 1.414L3.172 9.96l8.121 8.121z" />
              </svg>
            </a>
          </div>

          <a className={ styles.toggle } onClick={ onCloseClick }>
            <svg className={ styles.cross } fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
              <path fillRule="evenodd" clipRule="evenodd" d="M.838.838a1 1 0 011.415 0L7 5.586 11.748.838a1 1 0 111.414 1.415L8.414 7l4.748 4.748a1 1 0 01-1.415 1.414L7 8.414l-4.747 4.748a1 1 0 01-1.415-1.415L5.586 7 .838 2.253a1 1 0 010-1.415z" />
            </svg>
          </a>
        </header>

        <nav className={ styles.nav }>
          <PrimaryLinks 
            onLinkClick={ index => {
              setActivePrimary(index)
              setSecondaryOpen(true)
            }} 
          />

          <SecondaryLinks open={ secondaryOpen } activePrimary={ activePrimary } />
        </nav>
      </div>
    </CSSTransition>
  )
}

export default MobileMenu
import React from 'react'
import classnames from 'classnames'
import Button from '../../button'
import styles from './featureContent.module.scss'

const FeatureContent = props => {

    const getImage = () => {
        if (props.image.length) {
            return <img src={ props.image[0].url } />
        }
    }

    const getButton = () => {
        
        if ( props.button.length ) {
            return <Button { ...props.button[0] } />
        }
    }
    
    return (<div className={ classnames('py-5', 'page-component', 'feature-content', styles.root) }>
        <div className="inner-container">
            <div className={ classnames('row', styles.row, props.flipColumns ? styles.reversed : '') }>
                <div className={ classnames('col-12', 'col-md-6', styles.textCol ) }>
                    <div className={ styles.padded }>
                        <div className="rich-text" dangerouslySetInnerHTML={ { __html: props.richText } }></div>
                        { getButton() }
                    </div>
                </div>
                <div className={ classnames('col-12', 'col-md-6', styles.imgCol) }>
                    <div className={ styles.padded }>
                        { getImage() }
                    </div>
                </div>
            </div>  
        </div>
    </div>)
}

export default FeatureContent;
import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames'
import styles from './vimeoCard.module.scss'
import Button from '../../../button'
import Player from '@vimeo/player'

const VimeoCard = props => {

    const bigPlayerDimensions = {

    }

    const previewFrame = useRef();
    const bigPlayer = useRef();
    const modal = useRef();
    const clickTarget = useRef();

    const [aspectRatio, setAspectRatio] = useState(`58%`);

    const [previewPlayer, setPreviewPlayer] = useState({});
    const [featurePlayer, setFeaturePlayer] = useState({});

    const [playing, setPlaying] = useState(false);
    const [playerReady, setPlayerReady] = useState(false);

    const doAfter = (duration, callback) => {
        window.setTimeout(callback, duration);
    }

    const onMouseOver = () => {
        console.log('play video', previewPlayer);
        previewPlayer.setCurrentTime(0);
        previewPlayer.play();
    }

    const onMouseOut = () => {
        previewPlayer.pause();
        // previewPlayer.setCurrentTime(0);
        previewPlayer.unload();
    }

    const showBigPlayer = () => {

        featurePlayer.pause();
        featurePlayer.setCurrentTime(0);

        setPlayerReady(true);

        doAfter(100, () => {            
            setPlaying(true); 
            featurePlayer.play();
        });
        
    }

    const closeModal = ( e ) => {
        
        if ( e ) { 
            e.preventDefault(); 
            featurePlayer.pause();
        }

        setPlaying(false); 
            
        doAfter( 500, ()=> {
            setPlayerReady(false);
        });

    }

    const onClick = () => {
        //pause preview
        previewPlayer.pause();
        showBigPlayer();

    }

    useEffect(() => {
        
        //preview Player
        const preview =  new Player(previewFrame.current, { id: props.vimeoURL, controls: false, loop: true, muted: true });

        // make aspect ratio dynamically
        Promise.all([preview.getVideoWidth(), preview.getVideoHeight()]).then(function(dimensions) {
            var width = dimensions[0];
            var height = dimensions[1];
            const ratio = height / width;
            setAspectRatio(`${ratio * 100}%`);
        });

        const big =  new Player(bigPlayer.current, { id: props.vimeoURL, controls: true, loop: false, muted: false });

        big.on('ended', () => {
            closeModal();
        })

        setPreviewPlayer(preview);

        setFeaturePlayer(big);

    },[]);

    return (
        <div className={ classnames(styles.root, props.white ? styles.whitebg : '') }>
            <div className={ styles.content }>
                <div ref={ modal } className={ classnames(styles.modal, playerReady ? styles.modalReady : '', playing ? styles.modalActive : '') } >
                    <div className={ styles.bigPlayerWrapper }>
                        <a href="#" className={ styles.closer } onClick={ closeModal }>
                            <svg className={ styles.cross } fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                                <path fillRule="evenodd" clipRule="evenodd" d="M.838.838a1 1 0 011.415 0L7 5.586 11.748.838a1 1 0 111.414 1.415L8.414 7l4.748 4.748a1 1 0 01-1.415 1.414L7 8.414l-4.747 4.748a1 1 0 01-1.415-1.415L5.586 7 .838 2.253a1 1 0 010-1.415z" />
                            </svg>
                        </a>
                        <div ref={ bigPlayer } className={ styles.bigPlayer } style={ { paddingTop: aspectRatio } }></div>
                    </div>
                </div>
                <div className={ styles.previewWrapper }>
                    <div ref={ clickTarget } className={ styles.clickTarget } onMouseEnter={ onMouseOver } onMouseLeave={ onMouseOut } onClick={ onClick }>
                    <svg className={ styles.playIcon } width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><title>Artboard</title><g fill="none" fillRule="evenodd" stroke="#FFF"><circle cx="11" cy="11" r="9.5"/><path fill="#FFF" strokeLinejoin="round" d="M9.005 7.094v8l6-3.934z"/></g></svg>
                    </div>
                    <div ref={ previewFrame } className={ styles.frame } style={ { paddingTop: aspectRatio } }></div>
                </div>
                { props.videoTitle && <h6 className={ styles.cardHeadline }>{ props.videoTitle }</h6> }
                { props.videoDescription && <div className={ styles.description }>
                    <p className="small">{ props.videoDescription }</p>
                </div> }
                <div className={ styles.cta }>
                    { props.button.url && <Button type={ props.button.type } buttonText={ props.button.text } url={ props.button.url } smallSize={ true } noBackground={ false } noMargin={ true } />}
                </div>
            </div>
        </div>
    )
}

export default VimeoCard;
import axios from 'axios'

const instance = axios.create({
  baseURL: '/'
})

const resetPassword = async( loginName ) => {
  
  try {

    const { data } = await instance.request({
      url: '/password',
      method: 'post',
      data: {
        loginName: loginName
      }
    })

    return data
  } catch (error) {
    if (error.response) {
      const { data } = error.response

      return {
        success: false,
        message: data.message || 'Unknown error',
        statusCode: data.statusCode || 500,
        data: null
      }
    } else {
      return {
        success: false,
        message: 'Unknown error',
        statusCode: 500,
        data: null
      }
    }
  }
}

// returns { success, message, statusCode, data }
const authenticate = async (username, password) => {
  try {
    const { data } = await instance.request({
      url: '/authenticate',
      method: 'post',
      data: {
        loginName: username,
        password 
      }
    })

    return data
  } catch (error) {
    if (error.response) {
      const { data } = error.response

      return {
        success: false,
        message: data.message || 'Unknown error',
        statusCode: data.statusCode || 500,
        data: null
      }
    } else {
      return {
        success: false,
        message: 'Unknown error',
        statusCode: 500,
        data: null
      }
    }
  }
}

// returns { success, message, statusCode, data }
const mainNav = async (token) => {
  try {
    const { data } = await instance.request({
      url: '/api',
      method: 'post',
      data: { page: '$main-nav', token }
    })

    return data
  } catch (error) {
    if (error.response) {
      const { data } = error.response

      return {
        success: false,
        message: data.message || 'Unknown error',
        statusCode: data.statusCode || 500,
        data: null
      }
    } else {
      return {
        success: false,
        message: 'Unknown error',
        statusCode: 500,
        data: null
      }
    }
  }
}

const globalContent = async (token) => {

  try {
    const { data } = await instance.request({
      url: '/api',
      method: 'post',
      data: { page: '$globals', token }
    })
    
    return data

  } catch (error) {
    if (error.response) {
      const { data } = error.response

      return {
        success: false,
        message: data.message || 'Unknown error',
        statusCode: data.statusCode || 500,
        data: null
      }
    } else {
      return {
        success: false,
        message: 'Unknown error',
        statusCode: 500,
        data: null
      }
    }
  }
}

const CancelToken = axios.CancelToken
let source

// returns { success, message, statusCode, data }
const content = async (page, token) => {
  try {
    if (source) source.cancel()
    source = CancelToken.source()

    const { data } = await instance.request({
      url: '/api',
      method: 'post',
      data: { page, token },
      cancelToken: source.token
    })

    return data
  } catch (error) {
    if (error.response) {
      const { data } = error.response

      return {
        success: false,
        message: data.message || 'Unknown error',
        statusCode: data.statusCode || 500,
        data: null
      }
    } else {
      return {
        success: false,
        message: 'Unknown error',
        statusCode: 500,
        data: null
      }
    }
  }
}

export default {
  authenticate,
  resetPassword,
  mainNav,
  globalContent,
  content
}
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import styles from './loader.module.scss'
import { useCraft } from 'contexts/craft'

//const [ loaded, setLoaded ] = useState(false);

const Loader = () => {
    const { pageData, pageClasses } = useCraft()

    const [showControls, setShowControls] = useState(false);
    const [transitionType, setTransitionType ] = useState('fade');

    const toggleShown = React.useCallback(
        () => setShowControls
    )

    useEffect(()=>{
        document.onkeyup = e => {
            if ( e.key === 'Escape' ) {
                if ( showControls ) {
                    setShowControls( false );
                } else {
                    setShowControls( true );
                }
                    
            }
        }
    },[showControls]);

    if (!pageData) return null;

    return (
    <React.Fragment>
    <div className={ classnames(transitionType === 'fade' ? styles.noShow : styles.root, pageClasses.includes('pageLoading') ? styles.pageLoading : '') }>
        <div className="loader-content">
            Loading message or animated GIF etc...
        </div>
    </div>
    <div className={ classnames(styles.tempControls, showControls ? styles.showControls : '') }>
        <div className="mb-1"><label><input name="transitionType" checked={ transitionType === 'fade' } type="radio" value={ `fade` } onChange={ e => setTransitionType(e.target.value) } />Fade</label></div>
        <div className="mb-1"><label><input name="transitionType" checked={ transitionType !== 'fade' } type="radio" value={ `banner` } onChange={ e => setTransitionType(e.target.value) } />loading banner</label></div>
    </div>
    </React.Fragment>
    )
}

export default Loader
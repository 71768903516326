import React from 'react'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

import TertiaryLinks from './tertiary-links'

import { useGlobalContent } from 'contexts/global-content'

import styles from './secondary-links.module.scss'

const SecondaryLink = ({ active, label, menuIcon, menuIconHover, tertiary }) => {
  const rootClasses = classnames(
    styles.secondaryLink,
    { [styles.active]: active }
  )

  return (
    <div className={ rootClasses }>
      <div className={ styles.iconContainer }>
        <img className={ styles.iconImageHover } src={ menuIconHover[0].url } />
        <img className={ styles.iconImage } src={ menuIcon[0].url } />
      </div>
      
      <h6 className="title-3">{ label }</h6>

      <TertiaryLinks secondaryActive={ active } links={ tertiary } open={ true } />
    </div>
  )
}

const SecondaryLinks = ({ primaryActive, links, open }) => {
  const { activeNav } = useGlobalContent()

  return (
    <CSSTransition
      in={ open }
      timeout={ 200 }
      classNames={{ ...styles }}
      mountOnEnter
      unmountOnExit
    >
      <div className={ styles.secondaryLinks }>
        { links.map((secondary, index) => (
          <SecondaryLink key={ index } active={ primaryActive && activeNav[1] === index } { ...secondary } />
        )) }
      </div>
    </CSSTransition>
  )
}

export default SecondaryLinks
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuthentication } from 'contexts/authentication'

import api from 'utilities/api-client'

const GlobalContext = createContext()

const GlobalContentProvider = ({ children }) => {
    const [ loading, setLoading ] = useState(true)
    const [ footer, setFooter ] = useState(null)
    const [ navigation, setNavigation ] = useState(null)
    const [ activeNav, setActiveNav ] = useState([-1, -1, -1])
    const [ transparentHeader, setTransparentHeader ] = useState(false)
    const location = useLocation()
    const { token } = useAuthentication()

    const getGlobalContent = async () => {
        
        setLoading(true);

        const { data } = await api.globalContent(token);

        const footerContent = data?.globalSets.find( element => element.handle === 'footer');
        const navigationContent = data?.globalSets.find( element => element.handle === 'navigation');

        setNavigation({
            homeLinkLabel: navigationContent.homeLinkLabel,
            primaries: navigationContent.primary,
        })

        setFooter(footerContent);

        setLoading(false);
    }

    const getNewActiveNav = uri => {
        const newActiveNav = [-1, -1, -1]

        if (!Array.isArray(navigation.primaries)) return newActiveNav

        navigation.primaries.forEach((p, pI) => {
            if (!Array.isArray(p.secondary)) return

            p.secondary.forEach((s, sI) => {
                if (!Array.isArray(s.tertiary)) return

                s.tertiary.forEach((t, tI) => {
                    if (!Array.isArray(t.page) || t.page.length < 1) return

                    if (t.page[0].uri === uri) {
                        newActiveNav[0] = pI
                        newActiveNav[1] = sI
                        newActiveNav[2] = tI
                    }
                })
            })
        })

        return newActiveNav
    }

    useEffect(() => {
        if (navigation && location) {
            const uri = location.pathname.slice(1)
            const newActiveNav = getNewActiveNav(uri)

            setActiveNav(newActiveNav)
        }
    }, [location, navigation])

    useEffect(() => {
        getGlobalContent()
    }, [])

    const value = {
        loading,
        footer,
        navigation,
        transparentHeader,
        setTransparentHeader,
        activeNav
    }

    return (
        <GlobalContext.Provider value={ value }>
          { children }
        </GlobalContext.Provider>
      )
}

const useGlobalContent = () => useContext(GlobalContext)

export default GlobalContentProvider
export { useGlobalContent }

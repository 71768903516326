import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames';
import './imageCard.scss'
import Button from '../../../button'

const imageStyle = img => ({
    backgroundImage: `url(${ img.url })`,
    backgroundPositionX: `${img.focalPoint[0] * 100}%`,
    backgroundPositionY: `${img.focalPoint[1] * 100}%`,
});

const getButton = (hasPageLink, type, text, url) => {
    if ( hasPageLink ) {
        //just a button for visual the wrapping pageLink will handle the page transition
        return <button className={ classnames('btn', 'small') }>{ text }</button>
    }
    return <Button type={ type } buttonText={ text } url={ url } smallSize={ true } noBackground={ false } />
}

const CardContent = ({link, wrapper, children}) =>
    link ? wrapper(children) : children;

const ImageCard = props => {
    const image = props.cardImage.length ? props.cardImage[0] : null;
    const link = props.pageLink.length ? props.pageLink[0] : null;
    
    return (
        <div className={ `image-card ${props.white ? 'white-bg' : ''}` }>
            <CardContent link={ link } wrapper={ children => <Link to={ `/${link.uri}` } className="img-card-link">{ children }</Link> }>
                <Fragment>
                    { image && <header className="card-image-wrapper mb-1"><div className="card-image" style={ imageStyle(image) }></div></header> }
                    <div className={`card-content`}>
                        <h6 className="mb-1">{ props.headline }</h6>
                        <div className="description" dangerouslySetInnerHTML={ { __html: props.description } }></div>
                        { props.button.url && getButton(link, props.button.type, props.button.text, props.button.url) }
                    </div>
                </Fragment>
            </CardContent>
        </div>
    )
}

export default ImageCard;
import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { useGlobalContent } from 'contexts/global-content'

import styles from './tertiary-links.module.scss'

const TertiaryLink = ({ active, label, page }) => {
  const disabled = !page || page.length < 1

  const rootClasses = classnames(
    styles.tertiaryLink,
    { [styles.active]: active }
  )

  return (
    <div className={ rootClasses }>
      { disabled ?
        <p>{ label }</p> :
        <Link to={ `/${page[0].uri}` }>{ label }</Link>
      }
    </div>
  )
}

const TertiaryLinks = ({ secondaryActive, links, open }) => {
  const { activeNav } = useGlobalContent()

  const rootClasses = classnames(
    styles.tertiaryLinks,
    { [styles.open]: open }
  )

  return (
    <div className={ rootClasses }>
      { links.map((tertiary, index) => (
        <TertiaryLink key={ index } active={ secondaryActive && activeNav[2] === index } { ...tertiary } />
      )) }
    </div>
  )
}

export default TertiaryLinks
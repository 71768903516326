import React from 'react'
import './sectionHeader.scss'

const SectionHeader = props => {
    
    return (
        <div className={ `page-component section-header ${ props.first ? 'pt-5' : '' }` }>
            <div className="inner-container">
                <div className="row">
                    { props.line && <div className="col-12 mb-2"><hr/></div> }
                    <div className="col-12 col-md-6 pb-2">
                        { props.leftHeadline && <div dangerouslySetInnerHTML={ { __html: props.leftHeadline } }></div> }
                    </div>
                    <div className="col-12 col-md-6 pb-2">
                        { props.rightHeadline && <div dangerouslySetInnerHTML={ { __html: props.rightHeadline } }></div> }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionHeader;
import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames'

import styles from './login-background.module.scss'

import lockup from 'assets/lockup.svg'
import lockupFilled from 'assets/lockup-filled.svg'

const imageUrls = [
  '/assets/login/boxer.jpg',
  '/assets/login/kenya.jpg',
  '/assets/login/soccer-team.jpg',
  '/assets/login/yoga.jpg',
]

const LoginBackground = ({ children }) => {
  const [ image, setImage ] = useState(-1)
  const [ imageIn, setImageIn ] = useState(false)

  const loadImage = url => {
    return new Promise((resolve, reject) => {
      try {
        const image = new Image
        image.onload = resolve
        image.src = url
      } catch (error) {
        reject(error)
      }
    })
  }

  const showImage = async () => {
    try {
      await loadImage(imageUrls[image])
      setImageIn(true)
    } catch {
      // try another image?
      setImage((image + 1) % imageUrls.length)
      setTimeout(showImage, 500)
    }
  }

  const onImageEntered = () => {
    window.setTimeout(() => {
      setImageIn(false)
    }, 5000)
  }

  const onImageExited = () => {
    window.setTimeout(() => {
      setImage((image + 1) % imageUrls.length)
    }, 5000)
  }

  useEffect(() => {
    if (image > -1) {
      showImage()
    }
  }, [ image ])

  useState(() => {
    window.setTimeout(() => {
      setImage(0)
    }, 2500)
  }, [])

  return (
    <CSSTransition
      in={ imageIn }
      timeout={ 1000 }
      classNames={{ ...styles }}
      onEntered={ onImageEntered }
      onExited={ onImageExited }
    >
      <div className={ classnames(styles.root) }>
        <div
          className={ styles.image }
          style={{ backgroundImage: `url(${imageUrls[image]})` }}
        />

        <div className={ styles.blocker } />

        <div className={ styles.lockups }>
          <div className={ styles.lockup }>
            <div className="inner-container">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <img src={ lockup } />
                </div>
              </div>
            </div>
          </div>
          <div className={ styles.filledLockup }>
            <div className="inner-container">
            <div className="row">
                <div className="col-12 col-lg-8">
                  <img src={ lockupFilled } />
                </div>
              </div>
            </div>
          </div>
        </div>

        { children }
      </div>
    </CSSTransition>
  )
}

export default LoginBackground
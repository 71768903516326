import React from 'react'
import { useCraft } from 'contexts/craft'
import { Helmet } from "react-helmet";
import PageNavigation from 'components/pageNavigation'
import ContentBlocks from '../components/contentBlocks'
import './page-transitions/transitions.scss'
import Loader from 'components/loader'
import classnames from 'classnames'

import { useGlobalContent } from 'contexts/global-content'

const AuthenticatedPage = () => {
  const { pageData, pageClasses } = useCraft()
  const { transparentHeader } = useGlobalContent()

  if (!pageData) return null

  const bodyClasses = classnames(
    { 'dark': pageData.entry.uri === 'home', 'transparent-header': transparentHeader }
  )

  return (
    <React.Fragment>
      <div className={ `page ${pageClasses.join(' ')}` }>
        <Helmet><body className={  bodyClasses }/></Helmet>
        { pageData.entry.contentBlocks.map( (block, index) => ContentBlocks(block, index) ) }
      </div>
      <PageNavigation />
      <Loader />
    </React.Fragment>
  )
}

export default AuthenticatedPage
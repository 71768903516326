import React from 'react'
import styles from './writingAttributes.module.scss'
import classnames from 'classnames'

const WritingAttributes = props => {

    //just in case no cards are set 
    const cards = props.writingAttributes.length ? props.writingAttributes : null;

    return (
        <div className="page-component cards py-2">
            <div className="inner-container">
                <div className="row">
                    { cards && cards.map( (card, index) => (
                        <div className={ `col-12 col-sm-6 col-md-3 ${ styles.root }` } key={ index }>
                            <header className={ styles.attributes }>
                                <h5>
                                    <span className={ styles.affirmativeWord }>{ card.affirmativeWord }</span>
                                </h5>
                                <h5>
                                    <span className={ styles.negativeWord }>{ card.negativeWord }</span>
                                </h5>
                            </header>
                            <div className={ styles.description }><p className="small">{ card.description }</p></div>
                        </div>
                    )) }
                </div>
            </div>
        </div>
    )
}

export default WritingAttributes;
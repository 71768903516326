import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import styles from './page-navigation.module.scss'
import { useGlobalContent } from 'contexts/global-content'
import { useCraft } from 'contexts/craft'


const getNavLink = (parent, page) => {
    return {
        label: `${parent.label}: ${page.label}`,
        uri: page.page[0].uri
    };
}

const getPages = (nav, activePages) => {

    if ( activePages[0] >= 0 ) {
        const parent = nav.primaries[activePages[0]].secondary[activePages[1]];
        const children = parent.tertiary;

        return {
            'next': activePages[2] === children.length - 1 ? null : getNavLink( parent, children[ activePages[2] + 1] ),
            'prev': activePages[2] === 0 ? null : getNavLink( parent, children[ activePages[2] -1] )
        };

    }

    return {
        'next': null,
        'prev': null
    };

}

const PageNavigation = () => {
    const { navigation, activeNav } = useGlobalContent()
    const { pageData } = useCraft()
    const [pages, setPages] = useState(null)
    const [transitioning, setTransitioning] = useState(false)

    const navClasses = () => {
        if ( pages ) {
            return classnames(styles.root, transitioning ? styles.hide : '', pages.next && !pages.prev ? styles.onlyNext : '');
        }
        return classnames(styles.root, transitioning ? styles.hide : '');
    }

    useEffect(()=>{
        setPages(getPages( navigation, activeNav ));
    },[navigation]);


    useEffect(()=>{
        // delay updating the local state so it doesn't change before the next page loads.
        setTransitioning(true)

        window.setTimeout(()=>{
            setTransitioning(false)
            setPages(getPages( navigation, activeNav ))
        }, 1500);

    },[activeNav]);

    return (
        <div>
            { pageData.entry.showNextPrevious && <div className="inner-container pt-4">
                    <div className="row">
                        <div className="col-12">
                            { pages && <nav className={ navClasses() }>
                                { pages.prev && <Link to={ `/${pages.prev.uri}` } className={ styles.link }>
                                    <label>Last</label>
                                    <span>{ pages.prev.label }</span>
                                    </Link> }

                                { pages.next && <Link to={ `/${pages.next.uri}` } className={ styles.link }>
                                    <label>Next</label>
                                    <span>{ pages.next.label }</span>
                                    </Link> }
                                </nav> }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PageNavigation
import React, { useState, useEffect } from 'react'
import LoginBackground from 'components/login/login-background'
import LoginForm from 'components/login/login-form'
import LoginFooter from 'components/login/login-footer'
import NProgress from 'nprogress'

import { useAuthentication } from 'contexts/authentication'

const LoginPage = () => {
  const { login } = useAuthentication()
  const [errors, setErrors] = useState('');

  const submit = async (username, password) => {
    event.preventDefault()
    NProgress.start()

    try {

      await login(username, password)

    } catch (error) {

      if ( error.message.includes('email') ) {
        setErrors('loginName');
      } else {
        setErrors('password');
      }

      // alert(error.message)

    } finally {

      NProgress.done()

    }
  };


  return (
    <div className="absolute-fill">
      <LoginBackground>
        <LoginForm
          onSubmit={ submit }
          errors={ errors }
        />
        <LoginFooter />
      </LoginBackground>
    </div>
  )
}

export default LoginPage
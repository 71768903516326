import React, { useState, useRef } from 'react'
import { useAuthentication } from 'contexts/authentication'
import LostPasswordForm from 'components/password/lost-password-form'
import PasswordFooter from 'components/password/password-footer'
import classnames from 'classnames'
import styles from './page-styles/password-pages.module.scss'

const PasswordResetPage = () => {

  const { resetPassword } = useAuthentication();

  const [ errors, setErrors ] = useState('');
  const [ success, setSuccess ] = useState(false);

  const submit = async (loginName) => {

    event.preventDefault()
    // NProgress.start()

    try {
      console.log('sending password reset email');

      const result = await resetPassword(loginName);

      console.log( result );

      if ( !result.success ) {
        setErrors(result.message);
      } else {
        //show confirmation
        setSuccess(true);
      }

    } catch (error) {
      alert(error.message)
    } finally {
    //   NProgress.done()
    }
  };

  return (
    <div className="absolute-fill dark">
        <div className={ styles.root }>        
          <div className="inner-container">
            <div className={ classnames('row', 'vertical-centered', styles.passwordContent) }>
                <div className={ `col-12 col-sm-7 ${ styles.passwordHeadline }` }>
                    <h1 className={ classnames('outline', 'on-black', styles.headline )}>Forgot your password?</h1>
                </div>
                <div className="col-12 col-sm-5">
                  { !success && <LostPasswordForm
                    onSubmit={ submit }
                    errors={ errors }
                  /> }
                  { success && <div className={ styles.successMessage }>
                    <p>An e-mail with a link to reset your password has been sent to your inbox.</p>
                    <a className="btn on-black" href="/login">Return to Login</a>
                  </div> }
                </div>
            </div>
          </div>
        

        </div>
        
        <PasswordFooter />
    </div>
  )
}

export default PasswordResetPage
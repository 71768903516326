import React from 'react'
import LoginBackground from 'components/login/login-background'
import NProgress from 'nprogress'

import { useAuthentication } from 'contexts/authentication'

import PasswordResetForm from 'components/password/password-reset-form'

const PasswordResetPage = () => {

  const { resetPassword } = useAuthentication();

  const submit = async (loginName) => {
    event.preventDefault()
    NProgress.start()

    try {
      console.log('resetting password');
      const result = await resetPassword(loginName);

      console.log( result );
    } catch (error) {
      alert(error.message)
    } finally {
      NProgress.done()
    }
  };

  return (
    <div className="">
        <PasswordResetForm
          onSubmit={ submit }
        />
    </div>
  )
}

export default PasswordResetPage
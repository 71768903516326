import React from 'react'
import Button from '../../button'
import './imageBlock.scss'

const ImageWithCaption = props => {

    const getImage = () => {
        if (props.image.length) {
            return <img src={ props.image[0].url } />
        }

        //maybe return placeholder image if none exists? 
        //If we make the field required in Craft, than perhaps it's a non-issue.
        // return <img src="placeholder image url" />
    }
    
    return (<div className="page-component image-block inner-container py-2">
        { props.line && <div className="col-12 mb-2"><hr/></div> }
        <header dangerouslySetInnerHTML={ { __html: props.title } }></header>
        <div className="block-image-wrapper my-2">
            { getImage() }
        </div>
        <div className={ `image-description ${props.displayInColumns ? 'description-columns' : ''}` } dangerouslySetInnerHTML={ { __html: props.description } }>
            
        </div>
        { props.button && props.button.length > 0 && <div className="btn-wrapper"><Button {...props.button[0]} /></div> }
    </div>)
}

export default ImageWithCaption;
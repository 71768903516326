import React from 'react'
import classnames from 'classnames'
import styles from './page-header.module.scss'

const PageHeader = props => {
    
    const bannerImage = !!props.image.length ? props.image[0] : null;

    const bannerClasses = classnames(styles.imageBanner);

    const bannerStyle = bannerImage ? {
        backgroundImage: `url(${bannerImage.url})`,
        backgroundPositionX: `${bannerImage.focalPoint[0] * 100}%`,
        backgroundPositionY: `${bannerImage.focalPoint[1] * 100}%`,
    } : null;
   

    return (
        <header className={ `page-component page-header ${ props.first ? 'pt-5' : '' } mt-2 mb-2` }>
            <div className="inner-container pt-5 pb-1">
                { props.smallHeadline && <p>{ props.smallHeadline }</p>}
                <h1 className={ `${props.outlined ? 'outline' : ''}`}>{ props.headline }</h1>
            </div>
            { bannerImage && <div className={ bannerClasses } style={ bannerStyle }></div> }
            { props.line &&  <div className="inner-container py-1"><hr/></div> }
        </header>
    )
}

export default PageHeader;
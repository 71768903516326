import React from "react";

//import all components here
import TextBlock from '../textBlock'
import SectionHeader from '../sectionHeader'
import FeatureContent from '../featureContent'
import LineSpacer from '../lineSpacer'
import WritingAttributes from '../writingAttributes'


//map component names from Craft with react component names here
const Components = {
    sectionHeader: SectionHeader,
    textBlock: TextBlock,
    featureContent: FeatureContent,
    lineSpacer: LineSpacer,
    writingAttributes: WritingAttributes,
}

const WritingLayout = (props) => {

    const blocks = props.children.length > 0 ? props.children : null;

    return (<div className="page-component py-2">
        { blocks && blocks.map( (block, index) => {
            if ( typeof Components[ block.component ] !== 'undefined' ) {
                const BlockComponent = Components[ block.component ];
                return <BlockComponent first={ index === 0 } key={ block.id || index } { ...block } />
            }
        })}
    </div>);

}

export default WritingLayout;


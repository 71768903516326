import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './user-dropdown.module.scss'

const UserDropdown = ({ user, open, logout }) => {
    
    return (
      <CSSTransition
        in={ user && open }
        timeout={ 200 }
        classNames={{...styles}}
        mountOnEnter
        unmountOnExit
      >
      <div className={ styles.userDropdown }>
          <div className={ styles.userDropdownContent }>
            <header className={ styles.userDropdownHeader }>
              <p className={ styles.userName }>{ user.name }</p>
              <p className={ styles.userEmail }>{ user.email }</p>
            </header>
            <ul className={ styles.userDropdownMenu }>
              <li><a onClick={ ()=>{ logout(()=>{ window.location.href = '/lost-password' }) } }>Reset Password</a></li>
              <li><a onClick={ logout }>Log Out</a></li>
            </ul>
          </div>
      </div>
    </CSSTransition>)
  
}

export default UserDropdown
import React from 'react'
import classnames from 'classnames'

import styles from './login-footer.module.scss'

const LoginFooter = () => {
  return (
    <div className={ classnames(styles.root, 'inner-container') }>
      <div className="row">
        <div className="col-12"><div /></div>
        <div className="col-6">
          <a href="/lost-password">Forgot Password?</a>
        </div>
        <div className="col-6 flex content-end">
          <p>&copy; { new Date().getFullYear() } Nike, Inc.</p>
        </div>
      </div>
    </div>
  )
}

export default LoginFooter
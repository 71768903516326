import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import styles from './password-forms.module.scss'

const PasswordResetForm = ({ onSubmit }) => {
  const [ username, setUsername ] = useState('')

  const submit = async event => {
    event.preventDefault()
    onSubmit(username)
  }

  const submitterReady = () => {
    return username.length > 0 ? styles.ready : '';
  }

  return (
    <div className={ styles.root }>
      <div className="inner-container">
        <div className="row">
          <div className="col-12 col-lg-4 offset-lg-8">
                <h1>Reset Password</h1>
              <div className={ styles.form }>
                <form onSubmit={ submit }>

                  <div className={ styles.formGroup }>
                    <input className={ classnames(styles.fieldInput, username.length > 0 ? styles.valid : '') } type="text" name="email" value={ username } onChange={ e => setUsername(e.target.value) } />
                    <span></span>
                    <label htmlFor="email">Email</label>
                  </div>

                  <div className={ styles.submitGroup }>
                    <button className={ classnames(styles.submitter,  submitterReady()) } type="submit">Enter</button>
                  </div>
                  
                </form>
              </div>
          
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordResetForm
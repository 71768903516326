import React from "react";

//import all components here
import TextBlock from './textBlock'
import SectionTitle from './sectionTitle'
import SectionHeader from './sectionHeader'
import ImageBlock from './imageBlock'
import PageHeader from './pageHeader'
import CardLayout from './cardLayout'
import ImageCarousel from './imageCarousel'
import SideTabs from './sideTabs'
import FeatureContent from './featureContent'
import IframeEmbed from "./iframeEmbed";
import Banner from "./banner";
import LineSpacer from "./lineSpacer";
import WritingLayout from "./writingLayout"

//map component names from Craft with react component names here
const Components = {
    imageBlock: ImageBlock,
    pageHeader: PageHeader,
    sectionHeader: SectionHeader,
    sectionTitle: SectionTitle,
    textBlock: TextBlock,
    cardLayout: CardLayout,
    imageCarousel: ImageCarousel,
    sideTabs: SideTabs,
    featureContent: FeatureContent,
    iframeEmbed: IframeEmbed,
    banner: Banner,
    lineSpacer: LineSpacer,
    writingLayout: WritingLayout,
}

const ContentBlocks = (block, index) => {
    
    if ( typeof Components[ block.component ] !== 'undefined' ) {
        const BlockComponent = Components[ block.component ];
        return <BlockComponent first={ index === 0 } key={ block.id || index } { ...block } />
    }

}

export default ContentBlocks;


import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames'
import './imageCarousel.scss'
import Item from './item'

const ImageCarousel = props => {

    //state variables
    const [ ready, setReady ] = useState(false);
    const [ scrollable, setScrollable ] = useState(true);
    
    const carouselNode = useRef();
    const scrollImagesLength = props.images.length * 2;


    //timers
    let animationFrameID = null,
        resizeTimer = null;
    
    const scrollRefs = useRef([]);

    if ( scrollRefs.current.length !== scrollImagesLength ) {
        scrollRefs.current = Array(scrollImagesLength).fill().map((_,i) => React.createRef());
    }

    //image array used for scrolling
    const scrollingImages = props.images.concat(props.images).map((element, index) => {
        return {
            element: scrollRefs.current[ index ],
            url: element.url,
            x: 0,
            offset: 0,
            originalWidth: element.width,
            originalHeight: element.height,
            width: element.width,
            height: element.height,
        }
    });

    // image array used for stationary rendering
    const originalImages = props.images.map( element => ({
      element: null,
      url: element.url,
      originalWidth: element.width,
      originalHeight: element.height,
      width: element.width,
      height: element.height,  
    }));

    

    const mouse = useRef({
        down: false,
        startX: 0,
        lastX: 0,
        deltaX: 0,
    });

    const settings = useRef({
        itemMargin: 32,
        bufferLeft: 0,
        bufferRight: 0,
        tossThreshold: 5.0,
        scrollable: true,
        tossing: false,
        force: -1,
        defaultForce: -1,
        maxForce: 10,
        friction: 0.1,
        stage: {
            width: window.innerWidth,
            height: window.innerHeight,
            center: parseInt(window.innerWidth / 2),
        },
        centerIndex: Math.floor(scrollImagesLength / 2),
        currentCenterIndex: Math.floor(scrollImagesLength / 2),
        pause: false,
    });

    const getTotalWidth = () => {

        //set widths 
        originalImages.forEach( element => {
            const ratio = settings.current.stage.height / element.originalHeight;
            element.height = settings.current.stage.height;
            element.width = ratio * element.originalWidth;
        });

        return originalImages.reduce((total, item) => total + parseInt(item.width) + settings.current.itemMargin, 0);
    }

    const setStageDimensions = () => {
        const node = carouselNode.current.getBoundingClientRect();
        settings.current.stage.width = node.width;
        settings.current.stage.height = node.height;
    }

    const onAfterResize = () => {

        window.clearTimeout(resizeTimer);
        
        setStageDimensions();
        
        const isScrollable = getTotalWidth() > (settings.current.stage.width);

        //for the css class
        setScrollable(isScrollable);
        settings.current.scrollable = isScrollable;

        if ( isScrollable ) {
            setItemWidths();
            setItemOffsets();
            bufferItems();
            settings.current.pause = false;
        }
        
        setReady(true);
    }

    const loadImages = ( callback ) => {
        let total = props.images.length;

        props.images.forEach( image => {
            const img = new Image();
            img.onload = () => {
                if ( !--total ) {
                    callback();
                }
            }
            img.src = image.url;
        })
    }

    const setItemWidths = () => {
        return scrollingImages.forEach(item => {
            const ratio = settings.current.stage.height / item.originalHeight;
            item.height = settings.current.stage.height;
            item.width = ratio * item.originalWidth;
        });
    }

    const onMouseEnter = ( e ) => {
        settings.current.pause = true;
    }

    const onMouseLeave = ( e ) => {
        settings.current.pause = false;
        mouse.current.down = false;
    }

    const onMouseDown = ( e ) => {
        settings.current.pause = true;
        mouse.current.down = true;
        mouse.current.deltaX = 0;
        mouse.current.startX = e.pageX;
        mouse.current.lastX = mouse.current.startX;
        settings.current.force = 0;
        settings.current.tossing = false;
    }

    const onMouseMove = ( e ) => {
        if ( mouse.current.down ) {
            mouse.current.deltaX = e.pageX - mouse.current.lastX;
            mouse.current.lastX = e.pageX;
            settings.current.force = mouse.current.deltaX;
        }
    }

    const onMouseUp = ( e ) => {
        if ( mouse.current.down ) {
            mouse.current.down = false;
            if (Math.abs(mouse.current.startX - mouse.current.lastX) > settings.current.tossThreshold ) {
                settings.current.tossing = true;
            }
        }
    }

    const onWindowResize = () => {
        window.clearTimeout(resizeTimer);
        resizeTimer = window.setTimeout(onAfterResize, 100);
        settings.current.pause = true;
        setReady(false);
    }

    const events = () => {
        window.addEventListener('resize', onWindowResize);
    }

    const start = () => {
        setReady(true);
        tick();
    };

    const cleanup = () => {
        stop();
        window.removeEventListener('resize',onWindowResize);
    }

    const stop = () => {
        window.cancelAnimationFrame(animationFrameID);
    }

    const updateForce = () => {
      //if we're paused interpolate all force to zero
      if ( settings.current.pause ) {
        settings.current.force *= (1 - settings.current.friction);
      } else {
        settings.current.force += (settings.current.defaultForce - settings.current.force) * settings.current.friction;
      }

    };

    const findCenterItem = () => {

        let centerItem = null,
            centerIndex = 0,
            i = scrollingImages.length,
            distance = settings.current.stage.width;

        while ( i-- ) {
            if ( Math.abs(scrollingImages[ i ].x) < distance ) {
                centerItem = scrollingImages[ i ];
                distance = Math.abs(centerItem.x);
                centerIndex = i;
            }
        }

        if ( settings.current.currentCenterIndex !== centerIndex ) {
            settings.current.currentCenterIndex = centerIndex;
            setItemOffsets();
            bufferItems();
        }

    };

    const setItemOffsets = () => {

        let indexOffset = settings.current.currentCenterIndex - settings.current.centerIndex,
            sortCounter = indexOffset < 0 ? scrollingImages.length + indexOffset : indexOffset,
            pixelOffset = scrollingImages[ settings.current.currentCenterIndex ].x,
            centerItemWidth = scrollingImages[ settings.current.currentCenterIndex ].width / 2,
            sortedItems = [...scrollingImages];
            
        sortedItems = sortedItems.concat(sortedItems.splice(0,sortCounter));

        for ( let i = 0; i < scrollingImages.length; i++ ) {

            const direction = i < settings.current.centerIndex ? -1 : 1;
            
            const offset = (sortedItems.reduce((accumulator, item, index) => {
                if ( index < settings.current.centerIndex ) {
                    if ( index >= i && index < settings.current.centerIndex ) {
                        accumulator += (index === i ? item.width / 2 : item.width) + settings.current.itemMargin;
                    }
                } else {
                    if ( index <= i && index > settings.current.centerIndex ) {
                        accumulator += (index === i ? item.width / 2 : item.width) + settings.current.itemMargin;
                    }
                }
                return accumulator;
            }, 0) * direction) + pixelOffset;

            scrollingImages[ sortCounter ].offset = i !== settings.current.centerIndex ? offset + (centerItemWidth * direction) : offset;
            
            sortCounter = ++sortCounter % scrollingImages.length;
        }

    }

    const bufferItems = () => {
        let i = scrollingImages.length;
        
        while ( i-- ) {
            scrollingImages[ i ].x = scrollingImages[i].offset;
            move(scrollingImages[i]);
        }
    };

    const resetItems = () => {
        let i = items.length;

        while( i-- ) {
            items[ i ].x = 0;
            move(items[i].el, items[i].x);
        }
    }

    const moveItems = () => {
        let speed = settings.current.force,
            i = scrollingImages.length;

        while ( i-- ) {
            scrollingImages[ i ].x += speed;
            move(scrollingImages[i]);
        }
    };

    const tick = () => {
        animationFrameID = window.requestAnimationFrame(()=>{ tick(); });
        if ( settings.current.scrollable ) {
            updateForce();
            findCenterItem();
            moveItems();
        }
    };

    const move = (item) => { 
        item.element.current.style.transform = `translate3d(${item.x}px,0,0)`;
    }

    const setup = () => {
        //loading images first ensures they have width
        loadImages(()=>{ 
            setStageDimensions();
            setItemWidths();
            setItemOffsets();
            bufferItems();
            const isScrollable = getTotalWidth() > (settings.current.stage.width);
            //for the css class
            setScrollable(isScrollable);
            settings.current.scrollable = isScrollable;
            events();
            start();
        });
    };

    useEffect(()=>{
        
        setup();

        return () => {
            scrollRefs.current = Array();
            cleanup();
        }

    }, []);

    return (
        <section ref={ carouselNode } className={ `page-component carousel my-5 ${scrollable ? '' : 'no-scroll' } ${ props.first ? 'pt-5' : '' }` }>
            { scrollable && <div 
                onMouseEnter={ onMouseEnter } 
                onMouseLeave={ onMouseLeave }
                onMouseMove={ onMouseMove } 
                onMouseDown={ onMouseDown }
                onMouseUp={ onMouseUp }
                className={`image-carousel ${ready ? 'visible' : ''}`}>
                { scrollingImages.map( (item, index) => (
                    <img className="carousel-item" ref={ item.element } src={ `${item.url}?w=600` } width={ item.width } key={ index } />
                )) }
            </div> }

            { !scrollable && <div className="image-carousel-stationary">
                { originalImages.map( (item, index) => (<img className="carousel-item" src={ `${item.url}?w=600` } key={ index } />) ) }
                </div> }
        </section>
    )
}

export default ImageCarousel;
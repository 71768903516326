import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import styles from './button.module.scss'

/* 
* 
* type: internal / external / asset
* buttonText
* url
* pageLink
* smallSize
*
*/

const getURL = (props) => {
    
    if ( props.url ) {

        if ( props.type === 'entry' ) {
            //typed link plugin returns absolute URLs but we want URI
            return props.url.replace(/.*\.com?/,'');
        } else {
            return props.url;
        }
    } 

    if ( props.pageLink && props.pageLink.length > 0 ) {
        return `/${props.pageLink[0].uri}`;
    }

    if ( props.link_url ) {
        return props.link_url;
    }

    if ( props.asset && props.asset.length > 0 ) {
        return props.asset[0].url;
    }

}

const getButtonType = (key) => {
    switch( key ) {
        case 'button':
        case 'entry':
            return 'button';
            break;
        case 'external':
        case 'url':
        case 'externalLinkButton':
            return 'external';
            break;
        case 'asset':
        case 'assetDownloadButton':
            return 'asset';
            break;
        default:
            return null;
    }
} 

const buttonClasses = props => classnames('btn', props.smallSize ? 'small' : '', props.noBackground ? 'no-bg' : '', props.noMargin ? 'no-margin' : '');

const types = {
    'button': props => {
        return (
            <Link to={ getURL(props) } className={ buttonClasses(props) }><span className="btn-text">{ props.buttonText }</span></Link>
        );
    },
    'external': props => {
        return (
            <a target="_blank" href={ getURL(props) } className={ buttonClasses(props) }><span className="btn-text">{ props.buttonText }</span></a>
        );
    },
    'asset': props => {
        return (
            <a target="_blank" href={ getURL(props) } className={ buttonClasses(props) }> 
                <svg className="btn-icon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Download</title><path d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm.702 4.7H9.298v8.213l-2.227-2.22-.996.991 3.428 3.413a.704.704 0 0 0 .499.203.7.7 0 0 0 .494-.203l3.43-3.413-.992-.987-2.233 2.218V4.7z" fill="#000" fillRule="evenodd"/></svg>
                <span className="btn-text">{ props.buttonText }</span>
            </a>
        )
    }
}

const Button = props => {
    //map types so that we don't have to write the whole dumb Craft handle for externalLinkButton and assetDownloadButton
    const type = getButtonType(props.type);
    return types[ type ]( props );
}

export default Button;
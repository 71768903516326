import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Footer from '../components/footer'
import MainNavigation from 'components/main-navigation'

import AuthenticatedPage from 'pages/authenticated-page'
import NotFoundPage from 'pages/not-found-page'

import CraftProvider from 'contexts/craft'
import GlobalContentProvider from 'contexts/global-content'

const AuthenticatedRouter = () => {
  return (
    <Router>
      <GlobalContentProvider>
        <CraftProvider>
          <MainNavigation />
          <Switch location={ location }>
            <Route path="/login">
              <Redirect to="/" />
            </Route>
            <Route path="/not-found">
              <NotFoundPage />
            </Route>
            <Route path="*">
              <AuthenticatedPage />
            </Route>
          </Switch>
          <Footer />
        </CraftProvider>
      </GlobalContentProvider>
    </Router>
  )
}

export default AuthenticatedRouter